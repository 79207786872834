<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Şehir Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Şehir Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Şehir Listesi",
          href: "/city",
        },
        {
          text: "Şehir Ekle",
          active: true,
        },
      ],
      values: {
        city: "",
        plate: "",
        order: "",
      },
    };
  },
  methods: {
    submitNewHotel() {
      const api_url = process.env.VUE_APP_BASEURL + "/city";
      axios
        .post(api_url, this.values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/city");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submitNewHotel">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="order">Şehir Sırası:</label>
                    <input
                      v-model="values.order"
                      type="text"
                      class="form-control"
                      id="order"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="city">Şehir Adı:</label>
                    <input
                      v-model="values.city"
                      type="text"
                      class="form-control"
                      id="city"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="plate">Şehir Plakası:</label>
                    <input
                      v-model="values.plate"
                      type="text"
                      class="form-control"
                      id="plate"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Ekle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
